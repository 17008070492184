.f-container{
    justify-content: space-between;

}
.f-left{
    gap:1rem;
}
.f-menu{
    gap:2.5rem;
    margin-top:1.5rem;
    font-weight: 500;

}
.f-wrapper{
    display: flex;
    justify-content: space-between;
}
@media (max-width:768px) {
    .f-container{
        align-items: center!important;
        text-align: center;

    }
    
}