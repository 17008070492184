.hero-wrapper{
    color:white;
    position: relative;
    padding-bottom: 2rem;
    background-color: var(--black);
    z-index: 1;

}

.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}
.hero-left{
    gap:3rem;
  
}

.hero-title{
    position: relative;
    z-index: 1;
    /* top:70px;
    font-size: 30px;
    width: 100px;
    gap:5px; */

}
/* .Headings{
    display: flex;
    gap:1rem;

} */

.hero-title h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.orange-circle{
    height: 4rem;
    width: 4rem;
    background-color: orangered;
    /* background: var(--orage-gradient); */
    border-radius: 999px;
    position: absolute;
    right:28%;
    top:-10%;
    z-index: -1;
}


.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(225, 225,225,0.12);
}

.image-container img{
    width: 100%;
    height: 100%;
}

.search-bar{
    background-color: white;
    border-radius: 5px;
    border:3px solid rgba(120, 120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
}
.search-bar input{
    border:none;
    outline: none;
}
.stats{
    width: 100%;
    justify-content: space-between;
}
.stat>:nth-child(1) :last-child{
    color:orange;
}
.secondaryText{
    color:rgb(140 139 133);
    font-size:0.9rem;
}
@media (max-width:640px) {
    .hero-container{
        margin-top:2rem;
        /* position: absolute;
        top:-8rem;
        bottom: 50px; */
    }
    .hero-title h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap:1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .state>:nth-child(1){
        font-size: 0.8rem;
    }
   
}