.h-wrapper{
    background: var(--black);
    color:white;
    z-index: 99;

}
.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color:var(--secondary);
}
.h-menu{
    gap:2rem;
}
.button{
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color:white;
    background: var(--blue-gradient);
    border-radius: 4px;
    transition: all 300ms ease-in;
}
.button:hover{
    cursor: pointer;
    transform: scale(1.1);
}
.menu-icon{
    display: none;
}
@media (max-width: 760px) {
    .menu-icon{
        display: block;
    }
    .h-menu{
        color:var(--black);
        position:absolute;
        top:3rem;
        /* right: -25rem; */
        right:5rem;
        background: white;
        flex-direction: column;
        background-color: rgb(251, 251, 251);
        font-weight: 500;
        gap:2rem;
        padding:3rem;
        border-radius:10px;
        align-items: flex-start;
        z-index: 99;
        transition: all 300ms ease-in;
    }

    
}