.v-container .image-container{
    border: 8px solid rgb(232 232 232 /93%);
    width: 30%;
}

.v-container div{
    flex:1;
}
.v-container{
    display: flex;
    flex-direction: row;
    gap:30px;
    /* width: 600px; */
}
.accordion{
    border: none;
}
.v-right{
    gap:0.5rem;
    border: none;
    
}
.accordion{
    margin-top: 2rem;
    border: none;
}
.accordionItem{
    background: white;
    border:0.8px solid rgba(128, 128,128,0.148);
    border-radius: 8px;
    overflow: hidden;
    width: 30rem;
}
.accordionItem.expanded{
    box-shadow: var(--shadow);
    border-radius: 6px;
}
.accordionbutton{
        background-color: white;
        padding:1rem;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        margin:10px;
}

.icon{
    padding: 10px;
    background-color: #eeeeff;
    border-radius: 5px;
    

}

.icon svg{
    fill:var(--blue);
}

.accordionbutton .primaryText{
    font-size: 1.1rem;
}

.accordionbutton div{
    max-width: 4rem;
    margin:1px 5px ;
}
@media (max-width:1024px){
    .v-container{
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    @media (max-width: 768px) {
        .accordionbutton .primaryText{
            font-size: 0.8rem;
        }
        .image-container {
            align-items: center;
            text-align: center;

        }
        .v-container{
            flex-direction: column;
            text-align: center;
            justify-content: center;
        }
        .v-container .image-container{
            border: 8px solid rgb(232 232 232 /93%);
            width: 100%;
            text-align: center;
        }
        .accordionItem{
            width: 23rem;
            margin:0px;
        }
        .accordion{
            margin:0px;
        }
        
    }
}