.c-container{
    justify-content: space-between;
    /* flex-direction: row; */
    flex-wrap: wrap;
}
.c-container div{
    flex:1;
}
.c-right-container{
    width: 100%;
    flex-wrap: wrap;
}

.c-left{
    display: flex;
    gap:0.5rem;
    flex-wrap: wrap;
}

.contactModes{
    margin-top:2rem;
    gap:1rem;
    margin-right: 0px;
    margin-left:0px;
}
.contactModes .row{
    gap:1.5rem;

}
.Mode{
    width: 16rem;
    padding:1rem;
    border: 0.8px solid rgba(129,128128,0.143);
    border-radius: 5px;
    gap:1rem;
    transition:all 300ms ease-in;
    flex-wrap: wrap;
}
.Mode .button{
    width:100%;
    background: var(--lightBlue);
    color: var(--blue);
    font-size:0.9rem;
    font-weight: 600;
}
.Mode >:nth-child(1){
    width: 100%;
    gap:1.5rem;
}
.Mode .detail .primaryText{
    font-size:1.1rem;
    font-weight:600;
}
.Mode:hover{
    scale:1.1;
    box-shadow: var(--shadow);

}
.Mode .button:hover{
    background: var(--blue-gradient);
    color:white;
    scale:0.8;

}
.detail{
    flex-direction: column;
    flex-wrap: wrap;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}
.all-box{
    display: flex;
    gap:1rem;
    flex-wrap: wrap;
}
@media (max-width:1024px) {
    .c-container{
        flex-direction: column;
        
    }
    .c-right{
        justify-content: center;
    }
    .row{
        flex-direction: column;
        width: 100%;
    }
    .mode{
        width: 100%;
    }
    
}
@media (max-width:820px){
    .all-box{
        /* text-align: center; */
        margin-left: 3rem;
    }
    
}